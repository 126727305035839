<template>
    <div class="invoice-box">
        <table cellpadding="0" cellspacing="0">
            <tr class="top">
                <td colspan="2">
                    <table>
                        <tr>
                            <td class="title">
                                <img :srcset="assets('morelogo.png')" alt="MORE" title="MORE" />
                            </td>

                            <td>
                                No. Invoice: #{{order.ordersn}}<br />
                                Tanggal Pembelian: {{order.created_at_indonesia}}<br />
                                <!-- Selesai: February 1, 2021 -->
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>

            <tr class="information">
                <td colspan="2">
                    <table>
                        <tr>
                            <td>
                                {{ order.receiver_name }}<br />
                                {{ order.receiver_phone }}<br />
                                {{ order.address }}, {{ order.villages }}, Kec. {{ order.districts }}, {{ order.cities }}, {{ order.provinces }}, {{ order.postal_code }}
                            </td>

                            <!-- <td>
                                Acme Corp.<br />
                                John Doe<br />
                                john@example.com
                            </td> -->
                        </tr>
                    </table>
                </td>
            </tr>

            <tr class="heading2">
                <td>Metode Pembayaran</td>

                <td>Jasa Pengiriman</td>
            </tr>

            <tr class="details">
                <td>{{ order.faspay_name }}</td>

                <td>{{order.r_more_transaction_order_details[0].expedition}}</td>
            </tr>
        </table>

        <table cellpadding="0" cellspacing="0" class="table2">
            <tr class="heading2">
                <td>Produk</td>
                <td>Harga</td>
                <td>Jumlah</td>
                <td>Subtotal</td>
            </tr>

            <tr v-for="item in order.r_more_transaction_order_details[0].r_more_transaction_order_products" :key="item.id" class="item">
                <td>{{ item.r_uni_product_variant.name_long }}</td>
                <td>{{ item.price_rp }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.sub_total_rp }}</td>
            </tr>
        </table>
        <br>
        <table style="width: fit-content;;  margin-right: 0px; margin-left: auto;  border-top: 2px solid #eee; ">
                <tr class="total">
                    <td class="subtot">Subtotal</td>
                    <td class="subtot" style="text-align:right">{{sub_total}}</td>
                </tr>
                <tr class="total">
                    <td class="ongkir">Biaya Pengiriman</td>
                    <td style="text-align:right">{{total_ongkir}}</td>
                </tr>
                <tr v-if="order.voucher_code" class="total">
                    <td class="ongkir">Voucher <strong>#{{ order.voucher_code }}</strong></td>
                    <td style="text-align:right">-{{voucher}}</td>
                </tr>
                <tr class="totalakhir" style="">
                    <td class="finaltotal">Total Tagihan :</td>
                    <td class="finaltotal" style="text-align:right" colspan="4" >{{order.total_rp}}</td>
                </tr>
            </table>
    </div>
</template>

<script>
import globalUrl from '@/util/globallUrl'
import helpme from '@/util/helpme';  
const CHECKOUT = globalUrl.WS_TRANSACTION.CHECKOUT;
export default {
    name:"InvoiceNoAuth",
    data(){
        return {
            order: null,
            infoResi:'',
            jsonResi:[],
            sub_total:'',
            total_ongkir:'',
            voucher:''
        }
    },
    methods:{
        summary(){
            let sub_total = 0;
            let total_ongkir = 0;
            this.voucher = helpme.toRp(this.order.voucher_amount);
            this.order.r_more_transaction_order_details[0].r_more_transaction_order_products.map((item)=>{
                total_ongkir += _.toNumber(item.price_delivery);
                sub_total += _.toNumber(item.sub_total);
            });
            this.sub_total = helpme.toRp(sub_total);
            this.total_ongkir= total_ongkir > 0 ? helpme.toRp(total_ongkir) : 'Gratis';
        },
        
    },
    mounted(){
        // let retrieve  = JSON.parse(localStorage.getItem("detailOrder"));
        // this.order = retrieve.order;
        
        CHECKOUT.GET_DETAIL(this.$route.params.ordersn).then(response => {
            let _data = response.data.data.order;
            
            this.order = _data;
            this.summary();
            window.print();
            
            return true;
        }).catch(error => {
            // console.log("getdata",error)
            return false;
        });
    }
}
</script>

<style scoped lang="css">
    .invoice-box {
        max-width: 800px;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        font-size: 16px;
        line-height: 24px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        color: #555;
    }

    .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
    }

    .invoice-box table td {
        padding: 5px;
        vertical-align: top;
    }

    .invoice-box table tr td:nth-child(2) {
        text-align: right;
    }

    .invoice-box .table2 tr td:nth-child(1) {
        text-align: left;
        width: 40%;
    }

    .invoice-box .table2 tr td:nth-child(2) {
        text-align: left;
        width: 20%;
    }

    .invoice-box .table2 tr td:nth-child(3) {
        width: 10%;
        text-align: center;
    }

    .invoice-box .table2 tr td:nth-child(4) {
        width: 30%;
        text-align: right;
    }

    .invoice-box table tr.top table td {
        padding-bottom: 20px;
    }

    .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
    }

    .invoice-box table tr.top table td.title img {
        width: 200px;
    }

    .invoice-box table tr.information table td {
        padding-bottom: 40px;
    }

    .invoice-box table tr.heading td {
        background: #F6EFE5;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
    }

    .invoice-box table tr.details td {
        padding-bottom: 20px;
    }

    .invoice-box table tr.heading2 td {
        background: #F6EFE5;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
    }

    .invoice-box table tr.item td {
        border-bottom: 1px solid #eee;
    }

    .invoice-box table tr.item.last td {
        border-bottom: none;
    }

    .invoice-box table tr.total .subtot {
        text-align: left;
        /* border-image: linear-gradient(to left, #eee 50%, #FFF 50%) 100% 1; */
        font-weight: bold;
    }

    .invoice-box table tr.total .ongkir {
        text-align: left;
    }

    .totalakhir .finaltotal {
        text-align: left;
        font-weight: bolder;
        font-size: x-large;
    }

    @media only screen and (max-width: 600px) {
        .invoice-box {
            padding: 30px 0;
            box-shadow: none;

        }

        .invoice-box table tr.top table td {
            width: 100%;
            display: block;
            text-align: center;
        }

        .invoice-box table tr.information table td {
            width: 100%;
            display: block;
            text-align: center;
        }
    }

    /** RTL **/
    .invoice-box.rtl {
        direction: rtl;
        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    }

    .invoice-box.rtl table {
        text-align: right;
    }

    .invoice-box.rtl table tr td:nth-child(2) {
        text-align: left;
    }
</style>